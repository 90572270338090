import { createGlobalStyle, css, keyframes } from 'styled-components';

interface GlobalStyleProps {
  isLocked: boolean;
}

const RootDarkStyles = css`
  :root,
  :root[data-theme='dark'] {
    // static colors
    --black: #000000;
    --white: #ffffff;
    --bg: #1e1e1e;
    --bg-secondary: #242424;
    --link-hover-light: #c6c6c6;
    --link-hover-dark: #303030;
    --bg-popup--opacity: rgba(0, 0, 0, 0.65);
    --bg-notification--opacity: rgba(0, 0, 0, 0.5);
    --highlight-headers: rgba(255, 255, 255, 0.6);

    // background colors
    --bg-main: #343434;
    --bg-secondary: #2b2827;
    --bg-light: #ffffff;
    --bg-content: #3e3e3e;
    --bg-list-cell: #343434;
    --bg-list-cell-toast: #221e1c;
    --bg-modal-view: #1a1a1abf;
    --bg-custom-content: rgb(28, 26, 25);
    --bg-custom-content-rgb: 28, 26, 25;

    // control colors
    --control-primary: #65f406;
    --control-primary-hover: #6ef912;
    --control-disabled: #393939;
    --control-tertiary: #2f2f2f;
    --control-switcher-active: #0a98ff;
    --control-switcher-on-disabled: #004679;
    --control-secondary: #343434;
    --control-secondary-hover: #4b4b4b;
    --control-switcher-knob: #ffffff;
    --control-switcher-inactive: #413835;
    --control-switcher-of-disabled: #352f2c;
    --thumb-hover: #575757;

    // text colors
    --text-title: #fdfcfb;
    --text-regular: #ebe3e0;
    --text-subtitle: #dad3d0;
    --text-body: #bcb5b1;
    --text-disabled: #6d615c;
    --text-always-white: #ffffff;
    --text-always-dark: #221e1c;
    --text-link: #65f406;
    --text-link-light: #ebebeb;
    --text-error: #ff5a50;
    --text-toast: #221e1c;

    // icon colors
    --icon-main: #ded2cc;
    --icon-hover: #e5e1e0;
    --icon-disabled: #ded2cc99;
    --icon-always-white: #ffffff;
    --icon-always-dark: #292321;
    --icon-tab-bar: #70f433;
    --icon-toast: #292321;

    // input colors
    --input-outline: #717171;
    --input-focused: #7e7e7e;
    --input-disabled: #353535;
    --input-error: #ff5348;

    // divider colors
    --divider-main: #ffffff1a;

    // status colors
    --status-good: #65f406;
    --status-good-light: #3cb07e;
    --status-process: #39acff;
    --status-info: #f98600;
    --status-info-light: #ff8f57;
    --status-bad: #ff5348;
    --status-bad-light: #ff5348;
    --status-promo: #8e1efb;
  }
`;

const RootLightStyles = css`
  :root[data-theme='light'] {
    // static colors
    --black: #000000;
    --white: #ffffff;
    --bg: #1e1e1e;
    --link-hover-light: #c6c6c6;
    --link-hover-dark: #303030;
    --bg-popup--opacity: rgba(0, 0, 0, 0.65);
    --bg-notification--opacity: rgba(0, 0, 0, 0.5);
    --highlight-headers: rgba(255, 255, 255, 0.6);

    // background colors
    --bg-main: #ffffff;
    --bg-secondary: #cecece;
    --bg-light: #ffffff;
    --bg-content: #f4f4f4;
    --bg-list-cell: #fefefe;
    --bg-modal-view: #ccccccbf;
    --bg-custom-content: #f6f7fa;
    --bg-custom-content-rgb: 246, 247, 250;

    // control colors
    --control-primary: #368600;
    --control-primary-hover: #307700;
    --control-secondary: #fefefe;
    --control-secondary-hover: #e7f4e0;
    --control-tertiary: #787b77;
    --control-disabled: #bcc1b9;
    --control-switcher-on-disabled: #182068;
    --control-switcher-of-disabled: #e0e0e0;
    --control-switcher-active: #4659ff;
    --control-switcher-knob: #ffffff;
    --control-switcher-inactive: #989898;
    --thumb-hover: #575757;

    // text colors
    --text-title: #111111;
    --text-regular: #343434;
    --text-subtitle: #505050;
    --text-body: #696969;
    --text-disabled: #989898;
    --text-always-white: #111111;
    --text-always-dark: #f2f2f2;
    --text-link: #368600;
    --text-link-light: #111111;
    --text-error: #e12a1e;
    --text-toast: #221e1c;

    // icon colors
    --icon-main: #343434;
    --icon-hover: #242424;
    --icon-disabled: #34343499;
    --icon-always-white: #111111;
    --icon-always-dark: #f2f2f2;
    --icon-tab-bar: #368600;
    --icon-toast: #292321;

    // input colors
    --input-outline: #9f9f9f;
    --input-focused: #959595;
    --input-disabled: #cbcbcb;
    --input-error: #e12a1e;

    // divider colors
    --divider-main: #1111111a;

    // status colors
    --status-good: #40a100;
    --status-good-light: #3cb07e;
    --status-process: #4659ff;
    --status-info: #d17100;
    --status-info-light: #f6aa51;
    --status-bad: #e12a1e;
    --status-bad-light: #ff5348;
    --status-promo: #6a04ce;
  }
`;

export const noScroll = css`
  overflow: hidden;
  touch-action: none;
`;

export const flexColumnStart = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

/* TIP (T.V.): Skeleton animation styles */
export const shimmer = keyframes`
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
`;

export const skeletonAnimation = css`
  background: linear-gradient(
    90deg,
    var(--bg-list-cell) 25%,
    var(--control-secondary-hover) 50%,
    var(--bg-list-cell) 75%
  );
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ${RootDarkStyles};
  ${RootLightStyles};
  // :root

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: var(--bg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    overflow-x: hidden;
    min-width: 370px;
    
    ${({ isLocked }) => isLocked && noScroll};
  }

  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 70px;

    @media (max-width: 1039px) {
      padding-bottom: 100px;
    }
  }

  @keyframes pulse {
    0% {
      filter: drop-shadow(0 0 0px rgba(80, 244, 0, 0.3));
    }
    50% {
      filter: drop-shadow(0 0 9px rgba(80, 244, 0, 0.7));
    }
    100% {
      filter: drop-shadow(0 0 0px rgba(80, 244, 0, 0.3));
    }
  }

  .leaflet-marker-icon.highlight-marker {
    animation: pulse 2s ease-in-out infinite;
    animation-delay: 0.5s;
    transition: filter 2s ease-in-out;
  }

  a {
    text-decoration: none;
    color: var(--text-title);
  }

  h3, h2 {
    padding: 0;
    margin: 0;
  }

  .leaflet-attribution-flag {
    display: none !important;
  }

  .leaflet-touch .leaflet-bar a:first-child,
  .leaflet-touch .leaflet-bar a:last-child {
    user-select: none;
    -webkit-user-select: none;
  }

  .leaflet-container a.leaflet-popup-close-button {
    position: absolute;
    right: 3%;
    top: 5%;
    cursor: pointer;
    color: var(--white);
    transition: color 0.3s ease;
    user-select: none;
    -webkit-user-select: none;

    &:hover {
      color: var(--link-hover-light);
    }
  }

  /* TIP (T.V.): News list styles for React Virtualized container */
  .ReactVirtualized__Grid{
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    display: flex;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }

  .ReactVirtualized__Grid__innerScrollContainer::-webkit-scrollbar {
    display: none;
  }

  .ReactVirtualized__Grid__cell {
    scroll-snap-align: start;
  }
`;

import React from 'react';

import { useViewMode } from '../../../contexts/ViewModeContext';
import { IShopItem } from '../../../types';
import { SidebarContainer, SelectorContainer, ItemsContainer } from './styled';
import { ShopItem } from './ShopItem';
import { Dropdown } from './Dropdown';
import { useGetLocations } from './useGetLocations';

interface IProps {
  onShopItemClick: (latitude: number, longitude: number, shopInfo: IShopItem) => void;
  onMapInteraction: () => void;
}

export function Sidebar({ onShopItemClick }: IProps) {
  const { data: locations, handleCityChange, currentCity, cities } = useGetLocations();
  const { viewMode } = useViewMode();

  return (
    <SidebarContainer>
      <SelectorContainer>
        <Dropdown handleCityChange={handleCityChange} currentCity={currentCity} cities={cities} />
      </SelectorContainer>
      {viewMode === 'LIST' && (
        <ItemsContainer>
          {locations &&
            locations.map((location) => (
              <ShopItem
                shopInfo={location}
                key={location.id}
                onShopItemClick={onShopItemClick}
                isMessageActive={location.isMessageActive}
                activeMessage={location.isMessageActive ? location.activeMessage : undefined}
              />
            ))}
        </ItemsContainer>
      )}
    </SidebarContainer>
  );
}

import { useState } from 'react';

const useScrollHighlight = () => {
  const [highlightNews, setHighlightNews] = useState(false);
  const [highlightContacts, setHighlightContacts] = useState(false);

  const handleScrollToSection = (id: string) => {
    const offset = 20;

    if (id === 'top') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      const element = document.getElementById(id);

      if (element) {
        const elementPosition = element.offsetTop - offset;

        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth',
        });

        if (id === 'news') {
          setHighlightNews(true);

          setTimeout(() => setHighlightNews(false), 1500);
        } else if (id === 'contacts') {
          setHighlightContacts(true);

          setTimeout(() => setHighlightContacts(false), 1500);
        }
      }
    }
  };

  return {
    highlightNews,
    highlightContacts,
    handleScrollToSection,
  };
};

export default useScrollHighlight;

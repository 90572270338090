import React, { FC } from 'react';

import { ViewModeProvider } from './contexts/ViewModeContext';
import { ScrollLockProvider, useScrollLock } from './contexts/ScrollLockContext';
import { Header } from './components/Header';
import { InfoBlocks } from './components/InfoBlocks';
import { Main } from './components/Main';
import MetaTags from './components/MetaTags';
import useScrollHighlight from './components/InfoBlocks/useScrollHighlight';
import { GlobalStyle } from './globalsStyles';

const AppContent: FC = () => {
  const { highlightNews, highlightContacts, handleScrollToSection } = useScrollHighlight();
  const { isLocked } = useScrollLock();

  return (
    <>
      <MetaTags />
      <Header onScrollToSection={handleScrollToSection} />
      <Main />
      <InfoBlocks highlightNews={highlightNews} highlightContacts={highlightContacts} />
      <GlobalStyle isLocked={isLocked} />
    </>
  );
};

const App: FC = () => {
  return (
    <ScrollLockProvider>
      <ViewModeProvider>
        <AppContent />
      </ViewModeProvider>
    </ScrollLockProvider>
  );
};

export default App;

import PocketBase from 'pocketbase';

import { IShopItem } from '../types';
import { pocketbaseUrl } from '.';

const pocketbase = new PocketBase(pocketbaseUrl);

export const getShopList = async (sortParam = '-city') => {
  const result = await pocketbase.collection('shops').getFullList({
    sort: sortParam,
  });

  return result as IShopItem[];
};

export const getShopsByCity = async (city: string) => {
  const { items } = await pocketbase.collection('shops').getList(1, 50, {
    filter: pocketbase.filter(`city = {:city}`, { city }),
  });

  return items as IShopItem[];
};

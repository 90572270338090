import React, { FC, useEffect } from 'react';

import { supportData } from '../../../config';
import { useScrollLock } from '../../../contexts/ScrollLockContext';
import {
  ModalContainer,
  ModalContent,
  CloseButton,
  ModalSection,
  ModalLabel,
  ModalSectionInner,
  ModalIcon,
  ModalInnerContainer,
  ModalInnerHeading,
  ModalInnerSubHeading,
  ModalFooter,
  ModalFooterIcon,
  ModalFooterTip,
  ModalDivider,
  ModalTitle,
} from './styled';
import { SupportModalProps } from './types';

export const SupportModal: FC<SupportModalProps> = ({ isOpen, onClose }) => {
  const { setIsLocked } = useScrollLock();

  useEffect(() => {
    setIsLocked(isOpen);

    return () => {
      setIsLocked(false);
    };
  }, [isOpen, setIsLocked]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalContainer>
      <ModalContent>
        <CloseButton src={supportData.closeButton} onClick={onClose} loading="lazy" />
        <ModalTitle>{supportData.title}</ModalTitle>
        {supportData.sections.map((section, index) => {
          const isDividerShown = index < supportData.sections.length - 1;

          return (
            <React.Fragment key={index}>
              <ModalSection>
                <ModalLabel>{section.label}</ModalLabel>
                {section.items.map((item, idx) => (
                  <ModalSectionInner key={idx} href={item.href}>
                    <ModalIcon src={item.icon} />
                    <ModalInnerContainer>
                      <ModalInnerHeading>{item.heading}</ModalInnerHeading>
                      {item.subheading && (
                        <ModalInnerSubHeading>{item.subheading}</ModalInnerSubHeading>
                      )}
                    </ModalInnerContainer>
                  </ModalSectionInner>
                ))}
              </ModalSection>

              {isDividerShown && <ModalDivider />}
            </React.Fragment>
          );
        })}

        <ModalDivider />

        <ModalFooter href={supportData.footer.href} target="_blank">
          <ModalFooterIcon src={supportData.footer.icon} />
          <ModalFooterTip>{supportData.footer.tip}</ModalFooterTip>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  );
};

import styled from 'styled-components';

export const InfoBlocksContainer = styled.div`
  width: 100%;
  max-width: 1920px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1039px) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    align-items: start;
    justify-content: flex-start;
  }
`;

import styled from 'styled-components';
import { MapContainer as LeafletMapContainer } from 'react-leaflet';
import { Popup } from 'react-leaflet';
import { flexColumnStart } from '../../../globalsStyles';

export const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-grow: 1;
`;

export const MapInner = styled(LeafletMapContainer)`
  height: 100%;
  width: 100%;
  min-height: 500px;
`;

export const OverlayContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
`;

export const OverlayPopup = styled.div`
  background-color: var(--bg-notification--opacity);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 22px;
  border-radius: 15px;
`;

export const OverlayMessage = styled.p`
  color: var(--white);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin: 0;
`;

export const PopupContainer = styled(Popup)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border: 1.5px solid var(--control-primary);
  border-radius: 23px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  color: var(--white);
  background-color: var(--bg-popup--opacity);
  top: 20px;
  bottom: unset !important;

  @media (max-width: 719px) {
    padding: 16px 28px;
    top: 14px;
  }

  & * {
    all: unset;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  & .leaflet-popup-content-wrapper {
    all: unset;
    min-width: 260px;
  }

  & .leaflet-popup-content {
  }
`;

export const PopupWrapper = styled.div``;

export const PopupContent = styled.div`
  ${flexColumnStart};
  gap: 2px;
`;

export const PopupCity = styled.span`
  font-weight: 400;
  opacity: 0.8;
`;

export const PopupAddress = styled.span`
  white-space: nowrap;
  font-weight: 700;
`;

export const PopupHoursLabel = styled.label`
  white-space: nowrap;
`;

export const PopupHours = styled.span`
  font-weight: 700;
`;

export const PopupBreaksLabel = styled.label`
  white-space: nowrap;
`;

export const PopupBreaks = styled.span`
  text-align: left;
  font-weight: 700;
`;

export const PopupContentHead = styled.div`
  ${flexColumnStart};
  position: relative;
  gap: 4px;
  margin-bottom: 8px;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -6px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--text-title);
    opacity: 0.1;
  }
`;

export const PopupContentHours = styled.div`
  ${flexColumnStart};
`;

export const PopupContentBreaks = styled.div`
  ${flexColumnStart};
`;

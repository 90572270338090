import React from 'react';

import { Contacts } from './Contacts';
import { News } from './News';
import { InfoBlocksContainer } from './styled';

interface InfoBlocksProps {
  highlightNews: boolean;
  highlightContacts: boolean;
}

export function InfoBlocks({ highlightNews, highlightContacts }: InfoBlocksProps) {
  return (
    <InfoBlocksContainer>
      <Contacts highlightContacts={highlightContacts} />
      <News highlightNews={highlightNews} />
    </InfoBlocksContainer>
  );
}

import styled from 'styled-components';

import { IconArrowDown } from '../../../../assets';

export const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  padding: 20px 24px;
  gap: 8px;
  background-color: var(--bg);

  @media (max-width: 1039px) {
  }
`;

export const DropdownContainer = styled.select`
  width: 100%;
  min-height: 44px;
  padding: 12px 16px;
  border: none;
  border-radius: 6px;
  color: var(--text-title);
  background-color: transparent;
  background-image: url(${IconArrowDown});
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 95% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px var(--input-outline);
  z-index: 1;

  @media (max-width: 1039px) {
    background-position: 97% 50%;
  }

  @media (max-width: 499px) {
    background-position: 95% 50%;
  }
`;

export const DropdownOption = styled.option`
  background-color: var(--bg);
  color: var(--white);
  height: 100%;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    background-color: var(--control-secondary);
  }
`;

export const DropdownTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--text-title);
  margin-bottom: 2px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: start;
  flex-direction: column;
  flex-grow: 1;
`;

export const ViewWrapper = styled.div`
  display: none;
  min-height: 44px;
  gap: 8px;

  @media (max-width: 1039px) {
    display: flex;
  }
`;

export const View = styled.div`
  min-height: 44px;
  min-width: 56px;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--control-secondary);
  gap: 13px;
  border-radius: 6px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: var(--control-secondary-hover);
  }

  @media (max-width: 1039px) {
    padding: 10px;
  }
`;

export const ViewIcon = styled.img`
  width: 19px;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
`;

export const ViewTitle = styled.h3`
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  display: block;

  @media (max-width: 719px) {
    display: none;
  }
`;

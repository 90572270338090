export const URLs = {
  BET_NEWS_SITE: 'https://betnews.by/category/news/',
  WP_API_ENDPOINT: 'https://betnews.by/wp-json/wp/v2/posts',
};

export const Pagination = {
  INITIAL_PAGE: 1,
  PER_PAGE: 20,
  NUMBER_OF_SKELETONS: 6,
};

export const GridSizes = {
  CELL_WIDTH: 312,
  GRID_HEIGHT: 244,
};

export const SCROLL_THRESHOLD = 100;

export const SCROLL_CARDS = 3;

export const DEFAULT_RUBRIC = 'Другие виды спорта';

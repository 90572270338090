import React, { createContext, useContext, useState, ReactNode, FC } from 'react';

type ViewMode = 'LIST' | 'MAP';

interface ViewModeContextProps {
  viewMode: ViewMode;
  setViewMode: (mode: ViewMode) => void;
}

const ViewModeContext = createContext<ViewModeContextProps | undefined>(undefined);

export const ViewModeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [viewMode, setViewMode] = useState<ViewMode>('LIST');

  return (
    <ViewModeContext.Provider value={{ viewMode, setViewMode }}>
      {children}
    </ViewModeContext.Provider>
  );
};

export const useViewMode = () => {
  const context = useContext(ViewModeContext);

  if (context === undefined) {
    throw new Error('No context');
  }

  return context;
};

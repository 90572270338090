import React, { useEffect, useRef, FC } from 'react';
import L from 'leaflet';
import { Marker, useMap } from 'react-leaflet';

import { MapPointIcon } from '../../../assets/icons';
import { IShopItem } from '../../../types';
import CustomPopup from './CustomPopup';

interface CustomMarkerProps {
  position: [number, number];
  shopInfo: IShopItem;
  isHighlighted: boolean;
}

const CustomMarker: FC<CustomMarkerProps> = ({ position, shopInfo, isHighlighted }) => {
  const map = useMap();
  const markerRef = useRef<L.Marker>(null);

  useEffect(() => {
    if (shopInfo && markerRef.current) {
      const newZoom = map.getZoom() < 17 ? 17 : map.getZoom();

      map.flyTo(position, newZoom, {
        animate: true,
        duration: 1,
      });

      const timeout = setTimeout(() => {
        if (markerRef.current) {
          markerRef.current.getElement()?.classList.remove('highlight-marker');
        }
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [position, map, shopInfo]);

  const customIcon = L.icon({
    iconUrl: MapPointIcon,
    iconSize: [38, 38],
    iconAnchor: [22, 38],
  });

  useEffect(() => {
    if (isHighlighted && markerRef.current) {
      markerRef.current.getElement()?.classList.add('highlight-marker');
    } else if (markerRef.current) {
      const timeout = setTimeout(() => {
        markerRef.current?.getElement()?.classList.remove('highlight-marker');
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isHighlighted]);

  return (
    <Marker position={position} icon={customIcon} ref={markerRef}>
      {shopInfo && (
        <CustomPopup
          city={shopInfo.city}
          address={shopInfo.address}
          workingTime={shopInfo.workingTime}
          technicalPauses={shopInfo.technicalPauses}
        />
      )}
    </Marker>
  );
};

export default CustomMarker;

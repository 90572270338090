import React, { FC } from 'react';
import {
  PopupWrapper,
  PopupCity,
  PopupAddress,
  PopupHoursLabel,
  PopupHours,
  PopupBreaksLabel,
  PopupBreaks,
  PopupContent,
  PopupContainer,
  PopupContentHead,
  PopupContentHours,
  PopupContentBreaks,
} from './styled';
import { POPUP_HOURS_LABEL, POPUP_BREAKS_LABEL } from './mapConfig';

interface CustomPopupProps {
  city: string;
  address: string;
  workingTime: string;
  technicalPauses: string;
}

const CustomPopup: FC<CustomPopupProps> = ({ city, address, workingTime, technicalPauses }) => (
  <PopupContainer>
    <PopupWrapper>
      <PopupContent>
        <PopupContentHead>
          <PopupCity>{city}</PopupCity>
          <PopupAddress>{address}</PopupAddress>
        </PopupContentHead>

        <PopupContentHours>
          <PopupHoursLabel>{POPUP_HOURS_LABEL}</PopupHoursLabel>
          <PopupHours>{workingTime}</PopupHours>
        </PopupContentHours>

        <PopupContentBreaks>
          <PopupBreaksLabel>{POPUP_BREAKS_LABEL}</PopupBreaksLabel>
          <PopupBreaks>{technicalPauses}</PopupBreaks>
        </PopupContentBreaks>
      </PopupContent>
    </PopupWrapper>
  </PopupContainer>
);

export default CustomPopup;

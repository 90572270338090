import styled from 'styled-components';

export const LocationFinderContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const CityDropdown = styled.select`
  height: 40px;
  width: 200px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 48vw;
  max-width: 500px;
  overflow: hidden;
  background-color: var(--bg-secondary);

  @media (max-width: 1039px) {
    height: auto;
    width: 100%;
    max-width: none;
    max-height: 872px;
  }

  @media (max-width: 719px) {
    max-height: 1465px;
  }

  @media (max-width: 320px) {
    max-height: 1350px;
  }
`;

export const SelectorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1039px) {
    width: 100%;
    height: auto;
  }
`;

export const ItemsContainer = styled.div`
  padding: 12px 24px;
  flex-grow: 1;
  background-color: var(--bg-secondary);
  flex-grow: 1;
  overflow-y: scroll;
  height: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: var(--bg);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--control-primary);
    border-radius: 1px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--control-primary-hover);
  }

  @media (max-width: 719px) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 12px;
    padding: 8px;
  }
`;

export const MapContainer = styled.div`
  flex-grow: 1;
  position: relative;
  height: 100%;
  width: 75%;
`;

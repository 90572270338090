import React, { useState, useEffect, FC } from 'react';

import { MAP_ZOOM, DEFAULT_MAP_CENTER } from '../Main/Map/mapConfig';
import { useViewMode } from '../../contexts/ViewModeContext';
import { IShopItem } from '../../types';
import { getShopList } from '../../api/pocketbase';
import { MainContainer, MapWrapper } from './styled';
import { Map } from './Map';
import { Sidebar } from './Sidebar';

export const Main: FC = () => {
  const { viewMode } = useViewMode();
  const [mapCenter, setMapCenter] = useState<[number, number]>(DEFAULT_MAP_CENTER);
  const [mapZoom, setMapZoom] = useState<number>(MAP_ZOOM);
  const [allShops, setAllShops] = useState<IShopItem[]>([]);
  const [selectedShop, setSelectedShop] = useState<IShopItem | null>(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1040);
  const [loading, setLoading] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1040);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchShops = async () => {
      try {
        const shopList = await getShopList();
        if (isMounted) {
          setAllShops(shopList);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchShops();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleShopItemClick = (latitude: number, longitude: number, shopInfo: IShopItem) => {
    setIsInteractionBlocked(true);
    setMapCenter([latitude, longitude]);
    setMapZoom(16);
    setSelectedShop(shopInfo);
    setIsInteractionBlocked(false);
  };

  const handleMapInteraction = () => {
    setIsInteractionBlocked(true);
  };

  if (loading) {
    return <MainContainer id="bettingShops"></MainContainer>;
  }

  return (
    <MainContainer id="bettingShops">
      <Sidebar onShopItemClick={handleShopItemClick} onMapInteraction={handleMapInteraction} />
      {(viewMode === 'MAP' || isLargeScreen) && (
        <MapWrapper>
          <Map
            center={mapCenter}
            zoom={mapZoom}
            allShops={allShops}
            selectedShop={selectedShop}
            isInteractionBlocked={isInteractionBlocked}
          />
        </MapWrapper>
      )}
    </MainContainer>
  );
};

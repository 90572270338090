import React, { createContext, useContext, useState, ReactNode, FC } from 'react';

interface ScrollLockContextProps {
  isLocked: boolean;
  setIsLocked: (locked: boolean) => void;
}

const ScrollLockContext = createContext<ScrollLockContextProps | undefined>(undefined);

export const ScrollLockProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLocked, setIsLocked] = useState<boolean>(false);

  return (
    <ScrollLockContext.Provider value={{ isLocked, setIsLocked }}>
      {children}
    </ScrollLockContext.Provider>
  );
};

export const useScrollLock = (): ScrollLockContextProps => {
  const context = useContext(ScrollLockContext);

  if (context === undefined) {
    throw new Error('no Context');
  }

  return context;
};

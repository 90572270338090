import { useState, useEffect, useCallback } from 'react';

import { getShopList, getShopsByCity } from '../../../api';
import { extractUniqueCitiesFromArray } from '../../../utils/locations';
import { IShopItem } from '../../../types';

export function useGetLocations() {
  const [data, setData] = useState<IShopItem[]>([]);
  const [currentCity, setCurrentCity] = useState('');
  const [cities, setCities] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getDataByCity = async (city: string) => {
    try {
      const result = await getShopsByCity(city);
      setIsError(false);
      setIsLoading(false);
      setData(result);
    } catch {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const getData = async () => {
    try {
      const result = await getShopList();

      setCities(extractUniqueCitiesFromArray(result));
      setIsError(false);
      setIsLoading(false);
      setData(result);
    } catch {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const handleCityChange = useCallback((event: React.FormEvent<HTMLSelectElement>) => {
    const city = event.currentTarget.value;

    setCurrentCity(city);

    if (city) {
      getDataByCity(city);
    } else {
      getData();
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  return {
    isLoading,
    isError,
    data,
    handleCityChange,
    currentCity,
    cities,
  };
}

import React from 'react';

import { useViewMode } from '../../../../contexts/ViewModeContext';
import { ListViewIcon, MapViewIcon } from '../../../../assets/icons';
import {
  DropdownContainer,
  DropdownWrapper,
  DropdownTitle,
  DropdownOption,
  ViewWrapper,
  View,
  ViewIcon,
  ViewTitle,
  ControlWrapper,
} from './styled';

const VIEW_MODES = {
  LIST: 'Списком',
  MAP: 'На карте',
};

interface IProps {
  currentCity: string;
  cities: string[];
  handleCityChange: (event: React.FormEvent<HTMLSelectElement>) => void;
}

export function Dropdown({ handleCityChange, currentCity, cities }: IProps) {
  const { setViewMode } = useViewMode();

  return (
    <ControlWrapper>
      <DropdownWrapper>
        <DropdownTitle>Города</DropdownTitle>

        <DropdownContainer onChange={handleCityChange} value={currentCity}>
          <DropdownOption value="">Показать все города</DropdownOption>
          {cities.map((city) => (
            <DropdownOption key={city} value={city}>
              {city}
            </DropdownOption>
          ))}
        </DropdownContainer>
      </DropdownWrapper>

      <ViewWrapper>
        <View onClick={() => setViewMode('LIST')}>
          <ViewIcon src={ListViewIcon} draggable="false" loading="lazy" />
          <ViewTitle>{VIEW_MODES.LIST}</ViewTitle>
        </View>
        <View onClick={() => setViewMode('MAP')}>
          <ViewIcon src={MapViewIcon} draggable="false" loading="lazy" />
          <ViewTitle>{VIEW_MODES.MAP}</ViewTitle>
        </View>
      </ViewWrapper>
    </ControlWrapper>
  );
}
